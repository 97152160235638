export const STATUS = [
  {id: "enriching", value: "enriching"},
  {id: "enriched", value: "enriched"},
  {id: "contacted", value: "contacted"},
  {id: "connected", value: "connected"},
  {id: "meeting_booked", value: "meeting_booked"},
  {id: "meeting_done", value: "meeting_done"},
  {id: "opportunity", value: "opportunity"},
  {id: "won", value: "won"},
  {id: "lost", value: "lost"},
]

export const LANGUAGES = [
  { "id": "ar", "value": "ar" },
  { "id": "bg", "value": "bg" },
  { "id": "cs", "value": "cs" },
  { "id": "cy", "value": "cy" },
  { "id": "da", "value": "da" },
  { "id": "de", "value": "de" },
  { "id": "el", "value": "el" },
  { "id": "en", "value": "en" },
  { "id": "es", "value": "es" },
  { "id": "et", "value": "et" },
  { "id": "eu", "value": "eu" },
  { "id": "fa", "value": "fa" },
  { "id": "fi", "value": "fi" },
  { "id": "fr", "value": "fr" },
  { "id": "ga", "value": "ga" },
  { "id": "gl", "value": "gl" },
  { "id": "he", "value": "he" },
  { "id": "hi", "value": "hi" },
  { "id": "hr", "value": "hr" },
  { "id": "hu", "value": "hu" },
  { "id": "id", "value": "id" },
  { "id": "is", "value": "is" },
  { "id": "it", "value": "it" },
  { "id": "ja", "value": "ja" },
  { "id": "ko", "value": "ko" },
  { "id": "lt", "value": "lt" },
  { "id": "lv", "value": "lv" },
  { "id": "ms", "value": "ms" },
  { "id": "mt", "value": "mt" },
  { "id": "nl", "value": "nl" },
  { "id": "no", "value": "no" },
  { "id": "pl", "value": "pl" },
  { "id": "pt", "value": "pt" },
  { "id": "ro", "value": "ro" },
  { "id": "ru", "value": "ru" },
  { "id": "sk", "value": "sk" },
  { "id": "sl", "value": "sl" },
  { "id": "sr", "value": "sr" },
  { "id": "sv", "value": "sv" },
  { "id": "th", "value": "th" },
  { "id": "tr", "value": "tr" },
  { "id": "uk", "value": "uk" },
  { "id": "vi", "value": "vi" },
  { "id": "zh", "value": "zh" }
]

export const INDUSTRIES = [
  {"id": "accommodation_services", "value": "2190"},
  {"id": "food_and_beverage_services", "value": "34"},
  {"id": "bars_taverns_and_nightclubs", "value": "2217"},
  {"id": "caterers", "value": "2212"},
  {"id": "mobile_food_services", "value": "2214"},
  {"id": "restaurants", "value": "32"},
  {"id": "hospitality", "value": "31"},
  {"id": "bed_and_breakfasts_hostels_homestays", "value": "2197"},
  {"id": "hotels_and_motels", "value": "2194"},
  {"id": "administrative_and_support_services", "value": "1912"},
  {"id": "collection_agencies", "value": "1938"},
  {"id": "events_services", "value": "110"},
  {"id": "facilities_services", "value": "122"},
  {"id": "janitorial_services", "value": "1965"},
  {"id": "landscaping_services", "value": "2934"},
  {"id": "fundraising", "value": "101"},
  {"id": "office_administration", "value": "1916"},
  {"id": "security_and_investigations", "value": "121"},
  {"id": "security_guards_and_patrol_services", "value": "1956"},
  {"id": "security_systems_services", "value": "1958"},
  {"id": "staffing_and_recruiting", "value": "104"},
  {"id": "executive_search_services", "value": "1923"},
  {"id": "temporary_help_services", "value": "1925"},
  {"id": "telephone_call_centers", "value": "1931"},
  {"id": "translation_and_localization", "value": "108"},
  {"id": "travel_arrangements", "value": "30"},
  {"id": "writing_and_editing", "value": "103"}
]

export const COUNTRIES = [
  {"id": "AD", "value": "106296266"},
  {"id": "AE", "value": "104305776"},
  {"id": "AF", "value": "101240012"},
  {"id": "AG", "value": "100270819"},
  {"id": "AI", "value": "102287072"},
  {"id": "AL", "value": "102845717"},
  {"id": "AM", "value": "103030111"},
  {"id": "AO", "value": "105371935"},
  {"id": "AR", "value": "100446943"},
  {"id": "AS", "value": "102431220"},
  {"id": "AT", "value": "103883259"},
  {"id": "AU", "value": "101452733"},
  {"id": "AW", "value": "105763200"},
  {"id": "AZ", "value": "103226548"},
  {"id": "BA", "value": "102869081"},
  {"id": "BB", "value": "102118611"},
  {"id": "BD", "value": "106215326"},
  {"id": "BE", "value": "100565514"},
  {"id": "BF", "value": "100587095"},
  {"id": "BG", "value": "105333783"},
  {"id": "BH", "value": "100425729"},
  {"id": "BI", "value": "100800406"},
  {"id": "BJ", "value": "101519029"},
  {"id": "BM", "value": "100061709"},
  {"id": "BN", "value": "103809722"},
  {"id": "BO", "value": "104379274"},
  {"id": "BR", "value": "106057199"},
  {"id": "BS", "value": "106662619"},
  {"id": "BT", "value": "103613266"},
  {"id": "BW", "value": "105698121"},
  {"id": "BY", "value": "101705918"},
  {"id": "BZ", "value": "105912732"},
  {"id": "CA", "value": "101174742"},
  {"id": "CD", "value": "101271829"},
  {"id": "CF", "value": "100134827"},
  {"id": "CG", "value": "106796687"},
  {"id": "CH", "value": "106693272"},
  {"id": "CI", "value": "103295271"},
  {"id": "CL", "value": "104621616"},
  {"id": "CM", "value": "105745966"},
  {"id": "CN", "value": "102890883"},
  {"id": "CO", "value": "100876405"},
  {"id": "CR", "value": "101739942"},
  {"id": "CU", "value": "106429766"},
  {"id": "CV", "value": "101679268"},
  {"id": "CY", "value": "106774002"},
  {"id": "CZ", "value": "104508036"},
  {"id": "DE", "value": "101282230"},
  {"id": "DJ", "value": "100371290"},
  {"id": "DK", "value": "104514075"},
  {"id": "DM", "value": "100720695"},
  {"id": "DO", "value": "105057336"},
  {"id": "DZ", "value": "106395874"},
  {"id": "EC", "value": "106373116"},
  {"id": "EE", "value": "102974008"},
  {"id": "EG", "value": "106155005"},
  {"id": "ER", "value": "104219996"},
  {"id": "ES", "value": "105646813"},
  {"id": "ET", "value": "100212432"},
  {"id": "FI", "value": "100456013"},
  {"id": "FJ", "value": "105733447"},
  {"id": "FM", "value": "102762290"},
  {"id": "FR", "value": "105015875"},
  {"id": "GA", "value": "104471338"},
  {"id": "GB", "value": "101165590"},
  {"id": "GD", "value": "104579260"},
  {"id": "GE", "value": "106315325"},
  {"id": "GH", "value": "105769538"},
  {"id": "GI", "value": "100674501"},
  {"id": "GL", "value": "103705642"},
  {"id": "GM", "value": "106100033"},
  {"id": "GN", "value": "100099594"},
  {"id": "GQ", "value": "105141335"},
  {"id": "GR", "value": "104677530"},
  {"id": "GT", "value": "100877388"},
  {"id": "GW", "value": "100115557"},
  {"id": "GY", "value": "105836293"},
  {"id": "HK", "value": "103291313"},
  {"id": "HN", "value": "101937718"},
  {"id": "HR", "value": "104688944"},
  {"id": "HT", "value": "100993490"},
  {"id": "HU", "value": "100288700"},
  {"id": "ID", "value": "102478259"},
  {"id": "IE", "value": "104738515"},
  {"id": "IL", "value": "101620260"},
  {"id": "IN", "value": "102713980"},
  {"id": "IQ", "value": "106725625"},
  {"id": "IR", "value": "101934083"},
  {"id": "IS", "value": "105238872"},
  {"id": "IT", "value": "103350119"},
  {"id": "JM", "value": "105126983"},
  {"id": "JO", "value": "103710677"},
  {"id": "JP", "value": "101355337"},
  {"id": "KE", "value": "100710459"},
  {"id": "KG", "value": "103490790"},
  {"id": "KH", "value": "102500897"},
  {"id": "KI", "value": "104742735"},
  {"id": "KM", "value": "103069791"},
  {"id": "KN", "value": "102098694"},
  {"id": "KR", "value": "105149562"},
  {"id": "KW", "value": "103239229"},
  {"id": "KZ", "value": "106049128"},
  {"id": "LA", "value": "100664862"},
  {"id": "LB", "value": "101834488"},
  {"id": "LC", "value": "104022923"},
  {"id": "LI", "value": "100878084"},
  {"id": "LK", "value": "100446352"},
  {"id": "LR", "value": "106579411"},
  {"id": "LS", "value": "103712797"},
  {"id": "LT", "value": "101464403"},
  {"id": "LU", "value": "104042105"},
  {"id": "LV", "value": "104341318"},
  {"id": "LY", "value": "104036859"},
  {"id": "MA", "value": "102787409"},
  {"id": "MC", "value": "101352147"},
  {"id": "MD", "value": "106178099"},
  {"id": "ME", "value": "100733275"},
  {"id": "MG", "value": "105587166"},
  {"id": "MH", "value": "106516799"},
  {"id": "MK", "value": "103420483"},
  {"id": "ML", "value": "100770782"},
  {"id": "MM", "value": "104136533"},
  {"id": "MN", "value": "102396337"},
  {"id": "MO", "value": "101316508"},
  {"id": "MP", "value": "103666514"},
  {"id": "MR", "value": "106950838"},
  {"id": "MT", "value": "100961908"},
  {"id": "MU", "value": "106931611"},
  {"id": "MV", "value": "102161637"},
  {"id": "MW", "value": "105992277"},
  {"id": "MX", "value": "103323778"},
  {"id": "MY", "value": "106808692"},
  {"id": "MZ", "value": "100474128"},
  {"id": "NA", "value": "106682822"},
  {"id": "NE", "value": "103550069"},
  {"id": "NG", "value": "105365761"},
  {"id": "NI", "value": "105517145"},
  {"id": "NL", "value": "102890719"},
  {"id": "NO", "value": "103819153"},
  {"id": "NP", "value": "104630404"},
  {"id": "NR", "value": "100348836"},
  {"id": "NU", "value": "102139488"},
  {"id": "NZ", "value": "105490917"},
  {"id": "OM", "value": "103619019"},
  {"id": "PA", "value": "100808673"},
  {"id": "PE", "value": "102927786"},
  {"id": "PF", "value": "102681285"},
  {"id": "PG", "value": "100152180"},
  {"id": "PH", "value": "103121230"},
  {"id": "PK", "value": "101022442"},
  {"id": "PL", "value": "105072130"},
  {"id": "PT", "value": "100364837"},
  {"id": "PW", "value": "106779877"},
  {"id": "PY", "value": "104065273"},
  {"id": "QA", "value": "104170880"},
  {"id": "RO", "value": "106670623"},
  {"id": "RS", "value": "101855366"},
  {"id": "RU", "value": "101728296"},
  {"id": "RW", "value": "103547315"},
  {"id": "SA", "value": "100459316"},
  {"id": "SB", "value": "104980134"},
  {"id": "SC", "value": "107007050"},
  {"id": "SD", "value": "106740205"},
  {"id": "SE", "value": "105117694"},
  {"id": "SG", "value": "102454443"},
  {"id": "SI", "value": "106137034"},
  {"id": "SK", "value": "103119917"},
  {"id": "SL", "value": "106524187"},
  {"id": "SM", "value": "105730022"},
  {"id": "SN", "value": "103587512"},
  {"id": "SO", "value": "104725424"},
  {"id": "SR", "value": "105530931"},
  {"id": "SS", "value": "103622308"},
  {"id": "ST", "value": "106867470"},
  {"id": "SV", "value": "106522560"},
  {"id": "SY", "value": "106714035"},
  {"id": "SZ", "value": "106768907"},
  {"id": "TC", "value": "100771715"},
  {"id": "TD", "value": "104655384"},
  {"id": "TG", "value": "103603395"},
  {"id": "TH", "value": "105146118"},
  {"id": "TJ", "value": "105925962"},
  {"id": "TK", "value": "100212364"},
  {"id": "TL", "value": "101101678"},
  {"id": "TM", "value": "105449295"},
  {"id": "TN", "value": "102134353"},
  {"id": "TO", "value": "105646927"},
  {"id": "TR", "value": "102105699"},
  {"id": "TT", "value": "106947126"},
  {"id": "TV", "value": "103609605"},
  {"id": "TW", "value": "104187078"},
  {"id": "TZ", "value": "104604145"},
  {"id": "UA", "value": "102264497"},
  {"id": "UG", "value": "106943612"},
  {"id": "US", "value": "103644278"},
  {"id": "UY", "value": "100867946"},
  {"id": "UZ", "value": "107734735"},
  {"id": "VA", "value": "107163060"},
  {"id": "VC", "value": "104703990"},
  {"id": "VE", "value": "101490751"},
  {"id": "VG", "value": "105534858"},
  {"id": "VI", "value": "102119762"},
  {"id": "VN", "value": "104195383"},
  {"id": "VU", "value": "102185308"},
  {"id": "WF", "value": "104246629"},
  {"id": "WS", "value": "104031721"},
  {"id": "YE", "value": "105962095"},
  {"id": "YT", "value": "104097939"},
  {"id": "ZA", "value": "104035573"},
  {"id": "ZM", "value": "102120260"},
  {"id": "ZW", "value": "101367137"}
]

export const COMPANY_SIZES_MIN = [
  {id: "1", value: "1"},
  {id: "11", value: "11"},
  {id: "51", value: "51"},
  {id: "201", value: "201"},
  {id: "501", value: "501"},
  {id: "1001", value: "1001"},
  {id: "5001", value: "5001"},
  {id: "10001", value: "10001"},
]

export const COMPANY_SIZES_MAX = [
  {id: "10", value: "10"},
  {id: "50", value: "50"},
  {id: "200", value: "200"},
  {id: "500", value: "500"},
  {id: "1000", value: "1000"},
  {id: "5000", value: "5000"},
  {id: "10000", value: "10000"},
]
