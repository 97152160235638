import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf, faPlay, faForward, faComments, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Text from './Text'
import TextArea from './TextArea'

const ActionsSetup = ({ activeContact, activeSequence }) => {
  const { t } = useTranslation('global')
  const [actions, setActions] = useState([])

  const fetchActions = () => {
    activeContact && activeSequence ?
      apiClient.get(buildApiUrl(`/sequences/${activeSequence.id}/actions`, {contact_id: activeContact.id}))
        .then((response) => {
          setActions(response.data)
        })
        .catch((error) => {
          console.error('Error creating list:', error)
        })
      :
      setActions([])
  }

  const handleChangeAction = (index, field, value) => {
    setActions((prevActions) => {
      const updatedActions = [...prevActions]
      updatedActions[index] = { ...updatedActions[index], [field]: value }
      return updatedActions
    })
  }

  const handleSaveActions = (action) => {
    if (!action) {
      console.error('No action provided to save')
      return
    }
  
    const isNewAction = !action.id
    const url = isNewAction
      ? buildApiUrl(`/steps/${action.step_id}/actions`)
      : buildApiUrl(`/actions/${action.id}`)
  
    const requestMethod = isNewAction
      ? apiClient.post(url, action)
      : apiClient.put(url, action)
  
    requestMethod
      .then((response) => {
        const updatedAction = response.data
  
        setActions((prevActions) => {
          if (isNewAction) {
            return [...prevActions, updatedAction]
          } else {
            return prevActions.map((existingAction) =>
              existingAction.id === updatedAction.id ? updatedAction : existingAction
            )
          }
        })
  
        console.log('Action saved successfully:', updatedAction)
      })
      .catch((error) => {
        console.error('Error saving action:', error)
        alert('Failed to save the action. Please try again.')
      })
  }
  

  useEffect(() => {
    fetchActions()
  }, [activeContact, activeSequence])

  return (
    <div className='actions_setup'>
      {actions && actions.map((action, index) => {
        return (
          <div key={index} className='actions_setup__item'>
            <div className='actions_setup__item__infos'>
              <div className='actions_setup__item__step'>
                {t('step')} {action.order}
              </div>
              <FontAwesomeIcon icon={faForward} />
              {action.order === 1 ?
                <div className='actions_setup__item__info'>
                  <FontAwesomeIcon icon={faPlay} />
                  <span>{t('start')}</span>
                </div>
                :
                <div className='actions_setup__item__info'>
                  <FontAwesomeIcon icon={faHourglassHalf} />
                  <span>{t('wait')} {t(action.delay_days)} {t('days')}</span>
                </div>
              }
              <FontAwesomeIcon icon={faForward} />
              <div className='actions_setup__item__info'>
                {action.action_type === 'linkedin_message' &&
                  <FontAwesomeIcon icon={faComments} />
                }
                {action.action_type === 'email' &&
                  <FontAwesomeIcon icon={faPaperPlane} />
                }
                <span>{t(action.action_type)}</span>
              </div>
            </div>
            {action.action_type === 'email' &&
              <>
                <p className='actions_setup__item__label'>{t('subject')}</p>
                <Text
                  handleChangeValue={(e) => handleChangeAction(index, 'subject', e.target.value)}
                  value={action.subject}
                />
              </>
            }
            {['email', 'linkedin_message', 'linkedin_invite'].includes(action.action_type) &&
            <>
              <p className='actions_setup__item__label'>{t('body')}</p>
              <TextArea
                handleChangeValue={(newValue) => handleChangeAction(index, 'content', newValue)}
                value={action.content}
              />
            </>
            }
            <div className='actions_setup__item__cta__container'>
              <div className='main-btn' onClick={() => handleSaveActions(action)}>{t('save')}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ActionsSetup
