import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Filters from 'features/Filters'
import CompaniesResults from 'features/CompaniesResults'
import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const Companies = () => {
  const { t } = useTranslation('global')
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState({})
  const [companies, setCompanies] = useState([])

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = useCallback(() => {
    setCurrentFilters(newFilters)
  
    apiClient
      .get(buildApiUrl('/companies', newFilters))
      .then((response) => {
        setCompanies(response.data)
      })
      .catch((error) => {
        console.error('Error fetching companies:', error)
      })
  }, [newFilters])

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  useEffect(() => {
    handleSubmitFilters()
  }, [])

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])

  return (
    <Layout>
      <Topbar
        cta={t('save_search')}
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={t('companies')}
        number={companies ? companies.length : 0}
      />
      <Tabs
        activeTab={{name: t('my_companies'), id: '45434'}}
        tabs={[{name: t('my_companies'), id: '45434'}]}
      />
      <div className='companies'>
        <Filters
          handleChangeFilters={handleChangeFilters}
          type='companies'
          newFilters={newFilters}
        />
        <CompaniesResults
          companies={companies}
          handleCancelFilters={handleCancelFilters}
          handleSubmitFilters={handleSubmitFilters}
          hasNewFilters={hasNewFilters}
        />
      </div>
    </Layout>
  )
}

export default Companies
