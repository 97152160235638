import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faList, faBuilding } from '@fortawesome/free-solid-svg-icons'

const CompaniesResults = ({ companies, handleCancelFilters, handleSubmitFilters, hasNewFilters, type }) => {
  const { t } = useTranslation('search')

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={handleSubmitFilters}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {!hasNewFilters && companies.length > 0 &&
      
        <table className='results__table'>
          <thead>
            <tr>
              <th><input type='checkbox' /></th>
              <th>{t('name')}</th>
              <th>{t('industry')}</th>
              <th>{t('number_of_employees')}</th>
              <th>{t('headquarter')}</th>
              <th>{t('website_url')}</th>
              <th>{t('number_of_contacts')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => {
              return (
                <tr key={company.id}>
                  <td><input type='checkbox' /></td>
                  <td className='results__table__with-picture'>
                    {company.picture_url ?
                      <img src={company.picture_url} alt={company.name} className='results__table__picture' />
                      :
                      <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                    }
                    {company.linkedin_id ?
                      <a className='results__table__link' href={'https://www.linkedin.com/company/' + company.linkedin_id} target='_blank' rel="noreferrer">
                        {company.name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                      :
                      <div className='results__table__link'>
                        {company.name}
                      </div>
                    }
                  </td>
                  <td>{t(company.industry)}</td>
                  <td>{company.size}</td>
                  <td>{t(company.country)}</td>
                  <td>{company.website_url}</td>
                  <td>{company.number_of_contacts}</td>
                  <td className='results__actions'>
                    <FontAwesomeIcon className='results__actions__item' icon={faList} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }
    </div>
  )
}

export default CompaniesResults
