import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const Pagination = ({paginationContext, handleSubmitFilters}) => {
  const [pagesCursor, setPagesCursor] = useState({'1': ''})

  useEffect(() => {
    if(paginationContext && paginationContext.current_page === '1' && pagesCursor['2']) {
      setPagesCursor({'1': ''})
    }
    if (paginationContext && paginationContext.current_page && paginationContext.cursor) {
      setPagesCursor((prevPagesCursor) => ({
        ...prevPagesCursor,
        [paginationContext.current_page + 1]: paginationContext.cursor,
      }))
    }
  }, [paginationContext])

  return (
    <div className='pagination'>
      {paginationContext.current_page > 1 &&
        <div className='pagination__button' onClick={() => handleSubmitFilters(pagesCursor[paginationContext.current_page-1])}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      }
      <div className='pagination__page-number'>{paginationContext.current_page} / {paginationContext.total_page_count}</div>
      {pagesCursor[paginationContext.current_page+1] &&
        <div className='pagination__button' onClick={() => handleSubmitFilters(pagesCursor[paginationContext.current_page+1])}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      }
    </div>
  )
}

export default Pagination
