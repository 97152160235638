import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import Text from './Text'
import TextArea from './TextArea'

const SequenceStepEmail = ({ handleChangeCurrentStep, handleSaveStep, currentStep }) => {
  const { t } = useTranslation('global')
  const [text, setText] = useState('')
  const [subject, setSubject] = useState('')

  const handleSubmitText = () => {
    const newStep = { ...currentStep, content: text, subject: subject }
    handleChangeCurrentStep(newStep)
    handleSaveStep(newStep)
  }

  const handleChangeText = (newText) => {
    setText(newText)
  }

  const handleChangeSubject = (newSubject) => {
    setSubject(newSubject)
  }

  useEffect(() => {
    if(currentStep) {
      setText(currentStep.content)
      setSubject(currentStep.subject)
    }
  }, [currentStep])

  return (
    <div className='sequence-step-action'>
      <div className='sequence-step-action__tag'>
        <FontAwesomeIcon icon={faPaperPlane} />
        <p className='sequence-step-action__tag__text'>{t('send_email')}</p>
      </div>
        <p className='sequence-step-action__title'>{t('email_subject')}</p>
        <p className='sequence-step-action__desc'>{t('email_subject_desc')}</p>
        <div className='sequence-step-action__text__container'>
          <Text
            handleChangeValue={handleChangeSubject}
            value={subject}
          />
        </div>
        <p className='sequence-step-action__title'>{t('email_content')}</p>
        <p className='sequence-step-action__desc'>{t('email_content_desc')}</p>
        <div className='sequence-step-action__text__container'>
          <TextArea
            handleChangeValue={handleChangeText}
            value={text}
          />
        </div>
      <div className='main-btn' onClick={handleSubmitText}>{t('save')}</div>
    </div>
  )
}

export default SequenceStepEmail
