import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from 'contexts/AuthProvider'

import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const Profile = () => {
  const { t } = useTranslation('global')
  const { logout } = useAuth()

  return (
    <Layout>
      <Topbar
        cta={t('logout')}
        ctaAction={logout}
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={t('home')}
        number={''}
      />
      <Tabs
        activeTab={{name: t('my_companies'), id: '45434'}}
        tabs={[{name: t('my_companies'), id: '45434'}]}
      />
      <div className='profile'>
        
      </div>
    </Layout>
  )
}

export default Profile
