import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf, faCircleCheck, faPlus, faMinus, faBan } from '@fortawesome/free-solid-svg-icons'

const StepDelayChoice = ({ currentStep, handleChangeCurrentStep, previousStepIsLinkedinInvite }) => {
  const { t } = useTranslation('global')
  const [delay, setDelay] = useState(1)

  const handleChangeDelay = (newDelay) => {
    setDelay(newDelay)
    const newStep = { ...currentStep, delay_days: newDelay }
    handleChangeCurrentStep(newStep)
  }

  useEffect(() => {
    currentStep && currentStep.delay_days && setDelay(currentStep.delay_days)
  }, [currentStep])

  return (
    <div className='step-delay-choice'>
      <p className='step-delay-choice__title'>{t('add_delay')}</p>
      <p className='step-delay-choice__desc'>{t('delay_desc')}</p>
      <div className='step-delay-choice__list'>
        <div className={`${delay === 0 ? 'active' : ''} step-delay-choice__item`} onClick={() => handleChangeDelay(0)}>
          <div className='step-delay-choice__item__delay'>
            <FontAwesomeIcon icon={faBan} />
            <p className='step-delay-choice__item__delay__text'>{t('none')}</p>
          </div>
        </div>
        <div className={`${delay > 0 ? 'active' : ''} step-delay-choice__item days`}>
          <div className='step-delay-choice__days__selector' onClick={() => handleChangeDelay(Math.max(delay-1, 1))}>
            <FontAwesomeIcon icon={faMinus} />
          </div>
          <div className='step-delay-choice__item__delay'>
            <FontAwesomeIcon icon={faHourglassHalf} />
            <p className='step-delay-choice__item__delay__text'>{t('wait')} <span>{delay >= 0 ? delay : 0}</span> {t('days')}</p>
          </div>
          <div className='step-delay-choice__days__selector' onClick={() => handleChangeDelay(Math.max(delay+1,1))}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
        {previousStepIsLinkedinInvite &&
          <div className={`${delay === -1 ? 'active' : ''} step-delay-choice__item`} onClick={() => handleChangeDelay(-1)}>
            <div className='step-delay-choice__item__delay'>
              <FontAwesomeIcon icon={faCircleCheck} />
              <p className='step-delay-choice__item__delay__text'>{t('wait_for_invite')}</p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default StepDelayChoice
