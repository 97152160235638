import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Layout from 'layouts/Layout'
import Filters from 'features/Filters'
import PeopleResults from 'features/PeopleResults'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const People = () => {
  const { t } = useTranslation('global')
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState({})
  const [contacts, setContacts] = useState([])

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = useCallback(() => {
    setCurrentFilters(newFilters)
  
    apiClient
      .get(buildApiUrl('/contacts', newFilters))
      .then((response) => {
        setContacts(response.data)
      })
      .catch((error) => {
        console.error('Error fetching contacts:', error)
      })
  }, [newFilters])

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const handlePushAsList = () => {
    apiClient.post(buildApiUrl('/lists'), {name: 'All contacts', model: 'contact', list_type: 'dynamic', filters: []})
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error creating list:', error)
      })
  }

  const fetchLists = () => {
    apiClient.get(buildApiUrl('/lists', {model: 'contact', list_type: 'dynamic'}))
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error creating list:', error)
      })
  }

  useEffect(() => {
    handleSubmitFilters()
    fetchLists()
  }, [])

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])

  return (
    <Layout>
      <Topbar
        cta={t('save_as_list')}
        ctaAction={handlePushAsList}
        icon={<FontAwesomeIcon icon={faUserGroup} />}
        title={t('people')}
        number={contacts ? contacts.length : 0}
      />
      <Tabs
        activeTab={{name: t('my_companies'), id: '45434'}}
        tabs={[{name: t('my_companies'), id: '45434'}]}
      />
      <div className='people'>
        <Filters
          handleChangeFilters={handleChangeFilters}
          type='people'
          newFilters={newFilters}
        />
        <PeopleResults
          contacts={contacts}
          handleCancelFilters={handleCancelFilters}
          handleSubmitFilters={handleSubmitFilters}
          hasNewFilters={hasNewFilters}
          type='people'
        />
      </div>
    </Layout>
  )
}

export default People
