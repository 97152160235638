import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck ,faComments, faHourglassHalf, faPlay, faPhone, faUserPlus, faPaperPlane ,faPlus, faForwardFast } from '@fortawesome/free-solid-svg-icons'

const SequenceStepCreator = ({ currentStep, handleChangeStage, handleChangeCurrentStep, stage, steps }) => {
  const { t } = useTranslation('global')

  return (
    <div className='sequence_step_creator'>
      {steps && steps.length > 0 && steps.map((step, index) => {
        return (
          <div key={step.id}>
            {index > 0 && <div className='sequence_step_creator__separator'></div>}
            <div className={`${(currentStep && currentStep.action_type && currentStep.order) === step.order ? 'active' : ''} sequence_step_creator__item`} onClick={() => handleChangeCurrentStep(step)}>
              <div className='sequence_step_creator__number'>{index + 1}</div>
              {index === 0 &&
                <div className='sequence_step_creator__item__delay'>
                  <FontAwesomeIcon icon={faPlay} />
                  <p className='sequence_step_creator__item__delay__text'>{t('start')}</p>
                </div>
              }
              {index > 0 && step.delay_days > 0 &&
                <div className='sequence_step_creator__item__delay'>
                  <FontAwesomeIcon icon={faHourglassHalf} />
                  <p className='sequence_step_creator__item__delay__text'>{t('wait')} {step.delay_days} {t('days')}</p>
                </div>
              }
              {index > 0 && step.delay_days === -1 &&
                <div className='sequence_step_creator__item__delay'>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <p className='sequence_step_creator__item__delay__text'>{t('wait_for_invite')}</p>
                </div>
              }
              {index > 0 && step.delay_days === 0 &&
                <div className='sequence_step_creator__item__delay'>
                  <FontAwesomeIcon icon={faForwardFast} />
                  <p className='sequence_step_creator__item__delay__text'>{t('immediately')}</p>
                </div>
              }
              {step.action_type === 'linkedin_invite' &&
                <div className='sequence_step_creator__item__action'>
                  <FontAwesomeIcon icon={faUserPlus} />
                  <p className='sequence_step_creator__item__action__text'>{t('send_linkedin_invite')}</p>
                </div>
              }
              {step.action_type === 'linkedin_message' &&
                <div className='sequence_step_creator__item__action'>
                  <FontAwesomeIcon icon={faComments} />
                  <p className='sequence_step_creator__item__action__text'>{t('send_linkedin_message')}</p>
                </div>
              }
              {step.action_type === 'email' &&
                <div className='sequence_step_creator__item__action'>
                  <FontAwesomeIcon icon={faPaperPlane} />
                  <p className='sequence_step_creator__item__action__text'>{t('send_email')}</p>
                </div>
              }
              {step.action_type === 'call' &&
                <div className='sequence_step_creator__item__action'>
                  <FontAwesomeIcon icon={faPhone} />
                  <p className='sequence_step_creator__item__action__text'>{t('call')}</p>
                </div>
              }
            </div>
            <div className='sequence_step_creator__separator'></div>
            <div className='sequence_step_creator__add' onClick={() => handleChangeCurrentStep({order: step.order + 1})}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        )
      })}
      {steps && steps.length === 0 &&
        <div className='sequence_step_creator__add' onClick={() => handleChangeCurrentStep({order: 1})}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      }
      <div className='sequence_step_creator__cta-container'>
        <div className='main-btn' onClick={() => handleChangeStage(stage + 1)}>Next</div>
      </div>
    </div>
  )
}

export default SequenceStepCreator
