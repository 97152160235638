import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'
import SequenceBuilder from 'features/SequenceBuilder'
import SequenceShow from 'features/SequenceShow'

const Sequences = () => {
  const { t } = useTranslation('global')
  const [activeSequence, setActiveSequence] = useState({id: '', name: '', description: ''})
  const [sequencesList, setSequencesList] = useState([])
  const [isLoadingSequence, setIsLoadingSequence] = useState(false)
  const [currentStep, setCurrentStep] = useState()
  const [stage, setStage] = useState(1)
  const [activeContact, setActiveContact] = useState(null)
  
  const handleChangeTab = (sequenceId) => {
    const selectedSequence = sequencesList.find((s) => s.id === sequenceId)

    if (selectedSequence) {
      setActiveSequence(selectedSequence)
      setActiveContact(null)

      if (sequenceId !== 'new') {
        setIsLoadingSequence(true)
        apiClient
          .get(buildApiUrl(`/sequences/${sequenceId}`))
          .then((response) => {
            setActiveSequence(response.data)
            setActiveContact(null)
          })
          .catch((error) => {
            console.error('Error fetching sequence details:', error)
          })
          .finally(() => {
            setIsLoadingSequence(false)
          })
      }
    }
  }

  const handleChangeSequence = (key, value) => {
    return new Promise((resolve) => {
      setActiveSequence((prevSequence) => {
        const updatedSequence = {
          ...prevSequence,
          [key]: value,
        }
        resolve(updatedSequence)
        return updatedSequence
      })
      setCurrentStep(null)
    })
  }

  const handleSaveSequence = (updatedSequence) => {
    const sequence = updatedSequence || activeSequence
    const isNewSequence = sequence.id === "new"
    const url = isNewSequence
      ? buildApiUrl("/sequences")
      : buildApiUrl(`/sequences/${sequence.id}`)
  
    const requestMethod = isNewSequence
      ? apiClient.post(url, sequence)
      : apiClient.put(url, sequence)
  
    return requestMethod
      .then((response) => {
        setActiveSequence(response.data)
  
        setSequencesList((prevList) => {
          const updatedList = prevList.filter((sequence) => sequence.id !== "new")
  
          if (isNewSequence) {
            return [...updatedList, response.data]
          } else {
            return updatedList.map((sequence) =>
              sequence.id === response.data.id ? response.data : sequence
            )
          }
        })
      })
      .catch((error) => {
        console.error("Error saving sequence:", error)
      })
  }
  

  const fetchSequences = () => {
    return apiClient
      .get(buildApiUrl('/sequences'))
      .then((response) => {
        setSequencesList(response.data)
        if (response.data.length > 0) {
          setActiveSequence(response.data[0])
        }
      })
      .catch((error) => {
        console.error('Error fetching companies:', error)
      })
  }

  const handleCreateNewSequence = () => {
    setSequencesList((prevList) => [
      { id: 'new', name: '', description: '' },
      ...prevList,
    ])
    setActiveSequence({ id: 'new', name: '', description: '' })
  }

  const handleChangeCurrentStep = (step) => {
    setCurrentStep(step)
  }

  const handleChangeStage = (stage) => {
    setStage(stage)
    setCurrentStep(null)
    setActiveContact(null)
  }

  const handleSaveStep = (step) => {
    const isNewStep = step.id === undefined
    const url = isNewStep
      ? buildApiUrl(`/sequences/${activeSequence.id}/steps`)
      : buildApiUrl(`/sequences/${activeSequence.id}/steps/${step.id}`)

    const requestMethod = isNewStep
      ? apiClient.post(url, step)
      : apiClient.put(url, step)

    return requestMethod
      .then((response) => {
        setActiveSequence({ ...activeSequence, steps: response.data })
        setCurrentStep(response.data.find(s => s.order == step.order))
      })
      .catch((error) => {
        console.error("Error saving sequence:", error)
      })
  }

  const handleChangeActiveContact = (newContact) => {
    setActiveContact(newContact)
  }

  const hasNewSequence = sequencesList.some((sequence) => sequence.id === "new")

  const previousCurrentStep = (
    activeSequence && activeSequence.steps && activeSequence.steps.length > 0 && currentStep &&
    activeSequence.steps.filter(step => step.order < currentStep.order).sort((a, b) => b.order - a.order)[0]
  )
  
  useEffect(() => {
    fetchSequences()
  }, [])

  return (
    <Layout>
      <Topbar
        cta={t('create_sequence')}
        ctaAction={handleCreateNewSequence}
        ctaDisabled={hasNewSequence}
        icon={<FontAwesomeIcon icon={faPaperPlane} />}
        title={t('sequences')}
      />
      <Tabs
        activeTab={activeSequence}
        handleChangeTab={(sequenceId) => handleChangeTab(sequenceId)}
        tabs={sequencesList}
      />
      <div className='sequences'>
        <SequenceBuilder
          activeContact={activeContact}
          handleChangeActiveContact={handleChangeActiveContact}
          currentStep={currentStep}
          handleChangeCurrentStep={handleChangeCurrentStep}
          handleChangeStage={handleChangeStage}
          handleChangeSequence={handleChangeSequence}
          handleSaveSequence={handleSaveSequence}
          isLoading={isLoadingSequence}
          sequence={activeSequence}
          stage={stage}
        />
        <SequenceShow
          activeContact={activeContact}
          currentStep={currentStep}
          handleChangeCurrentStep={handleChangeCurrentStep}
          handleSaveStep={handleSaveStep}
          previousStepIsLinkedinInvite={previousCurrentStep && previousCurrentStep.action_type === 'linkedin_invite'}
          sequence={activeSequence}
          stage={stage}
        />
      </div>
    </Layout>
  )
}

export default Sequences
