import React from 'react'

import ActionsSetup from 'components/ActionsSetup'
import SequenceStepSetup from 'components/SequenceStepSetup'

const SequenceShow = ({ activeContact, currentStep, handleChangeCurrentStep, handleSaveStep, previousStepIsLinkedinInvite, sequence, stage }) => {

  return (
    <div className='sequence-show'>
      {stage === 2 &&
        <SequenceStepSetup
          currentStep={currentStep}
          handleChangeCurrentStep={handleChangeCurrentStep}
          handleSaveStep={handleSaveStep}
          previousStepIsLinkedinInvite={previousStepIsLinkedinInvite}
        />
      }
      {stage === 3 &&
        <ActionsSetup
          activeContact={activeContact}
          activeSequence={sequence}
        />
      }
    </div>
  )
}

export default SequenceShow
