import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const Search = ({ handleChangeFilters, item, isDisabled, newValue }) => {
  const { t } = useTranslation('search')
  const [value, setValue] = useState(newValue || '')
  const [search, setSearch] = useState(newValue || '')

  const handleChangeValue = (text) => {
    setValue(text)
    setSearch('')
  }

  const handleRemoveValue = () => {
    setValue('')
    setSearch('')
    handleChangeFilters(item, null)
  }

  const handleSubmit = (text) => {
    setSearch(text)
    handleChangeFilters(item, text)
  }

  useEffect(() => {
    setValue(newValue || '')
    setSearch(newValue || '')
  }, [newValue])

  return (
    <div className={isDisabled ? 'search disabled' : 'search'}>
      {search ?
        <div className='search__value'>
          <span>{search}</span>
          <FontAwesomeIcon className='search__value__trash' icon={faTrash} onClick={handleRemoveValue} />
        </div>
        :
        <form onSubmit={() => handleSubmit(value)}>
          <input
            className='search__input'
            disabled={!!isDisabled}
            onChange={(e) => handleChangeValue(e.target.value)}
            placeholder={t('search')}
            value={value}
          />
        </form>
      }
    </div>
  )
}

export default Search
