const capitalizeFirstLetter = str => str ? str.replace(/^[a-z]/, match => match.toUpperCase()) : ''

const buildApiUrl = (basePath, filters, page) => {
  const query = filters ?
    Object.entries(filters)
      .filter(([_, value]) => value !== null && value !== '')
      .map(([key, value]) =>
        `${encodeURIComponent(
          key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
        )}=${encodeURIComponent(value)}`
      )
      .join("&")
    :
    ''

  return `${basePath}${query ? `?${query}${page ? `&page=${page}` : ''}` : page ? `?page=${page}` : ''}`
}

export { capitalizeFirstLetter, buildApiUrl }
