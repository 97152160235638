import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faEnvelope, faUserPlus, faUser, faPaperPlane, faList, faPhone, faSpinner, faThumbsUp, faBuilding } from '@fortawesome/free-solid-svg-icons'

import { capitalizeFirstLetter } from 'services/functions'

const PeopleResults = ({ contacts, handleCancelFilters, handleSubmitFilters, hasNewFilters, type }) => {
  const { t } = useTranslation('search')

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={handleSubmitFilters}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {!hasNewFilters && contacts.length > 0 &&
      
        <table className='results__table'>
          <thead>
            <tr>
              <th><input type='checkbox' /></th>
              <th>{t('name')}</th>
              <th>{t('job_title')}</th>
              <th>{t('company')}</th>
              <th>{t('status')}</th>
              <th>{t('email')}</th>
              <th>{t('phone')}</th>
              <th>{t('company_size')}</th>
              <th>{t('industry')}</th>
              <th>{t('country')}</th>
              <th>{t('language')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => {
              return (
                <tr key={contact.id}>
                  <td><input type='checkbox' /></td>
                  <td className='results__table__with-picture'>
                    {contact.picture_url ?
                      <img src={contact.picture_url} alt={contact.first_name + ' ' +contact.last_name} className='results__table__picture' />
                      :
                      <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faUser} />
                    }
                    {contact.linkedin_id ?
                      <a className='results__table__link' href={'https://www.linkedin.com/in/' + contact.linkedin_id} target='_blank' rel="noreferrer">
                        {contact.first_name} {contact.last_name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                      :
                      <div className='results__table__link'>
                        {contact.first_name} {contact.last_name}
                      </div>
                    }
                  </td>
                  <td>{contact.job_title}</td>
                  <td className='results__table__with-picture'>
                    {contact.company_picture_url ?
                      <img src={contact.company_picture_url} alt={contact.company_name} className='results__table__picture' />
                      :
                      <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                    }
                    {contact.company_linkedin_id ?
                      <a className='results__table__link' href={'https://www.linkedin.com/company/' + contact.company_linkedin_id} target='_blank' rel="noreferrer">
                        {contact.company_name ? contact.company_name : 'Unknown'}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                      :
                      <div className='results__table__link'>
                        {contact.company_name ? contact.company_name : 'Unknown'}
                      </div>
                    }
                  </td>
                  <td><div className={`results__table__status ${contact.status}`}>
                    {contact.status === 'enriching' && <FontAwesomeIcon className='results__table__status__icon' icon={faSpinner} />}
                    {contact.status === 'enriched' && <FontAwesomeIcon className='results__table__status__icon' icon={faThumbsUp} />}
                    <span>{capitalizeFirstLetter(contact.status)}</span>
                  </div></td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.company_size}</td>
                  <td>{t(contact.company_industry)}</td>
                  <td>{t(contact.country)}</td>
                  <td>{t(contact.language)}</td>
                  <td className='results__actions'>
                    {type === 'search' && <FontAwesomeIcon className='results__actions__item' icon={faUserPlus} />}
                    <FontAwesomeIcon className='results__actions__item' icon={faList} />
                    <FontAwesomeIcon className='results__actions__item' icon={faPaperPlane} />
                    {type === 'people' && contact.is_validated_email && <FontAwesomeIcon className='results__actions__item' icon={faEnvelope} />}
                    {type === 'people' && contact.is_validated_phone && <FontAwesomeIcon className='results__actions__item' icon={faPhone} />}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }
    </div>
  )
}

export default PeopleResults
