import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'

import LanguageSwitcher from 'features/LanguageSwitcher'
import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const Settings = () => {
  const { t } = useTranslation('global')

  return (
    <Layout>
      <Topbar
        icon={<FontAwesomeIcon icon={faGear} />}
        title={t('settings')}
      />
      <Tabs
        activeTab={{name: t('my_companies'), id: '45434'}}
        tabs={[{name: t('my_companies'), id: '45434'}]}
      />
      <div className='settings'>
        <LanguageSwitcher />
      </div>
    </Layout>
  )
}

export default Settings
