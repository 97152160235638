import React from 'react'

const Integrations = () => {

  return (
    <div>
      <h1>Integrations</h1>
    </div>
  )
}

export default Integrations
