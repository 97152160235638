import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enGlobal from './locales/en/global.json'
import enHome from './locales/en/home.json'
import enSearch from './locales/en/search.json'
import frGlobal from './locales/fr/global.json'
import frHome from './locales/fr/home.json'
import frSearch from './locales/fr/search.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        global: enGlobal,
        home: enHome,
        search: enSearch,
      },
      fr: {
        global: frGlobal,
        home: frHome,
        search: frSearch,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
