import React from 'react'
import { useTranslation } from 'react-i18next'

import SequenceStepEmail from './SequenceStepEmail'
import SequenceStepLinkedinInvite from './SequenceStepLinkedinInvite'
import SequenceStepLinkedinMessage from './SequenceStepLinkedinMessage'
import SequenceStepChoices from './SequenceStepChoices'
import StepDelayChoice from './StepDelayChoice'

const SequenceStepSetup = ({ currentStep, handleChangeCurrentStep, handleSaveStep, previousStepIsLinkedinInvite }) => {
  const { t } = useTranslation('search')

  return (
    <div className='sequence-step-setup'>
      {currentStep && !currentStep.action_type &&
        <>
          {currentStep.order > 1 &&
            <StepDelayChoice
              currentStep={currentStep}
              handleChangeCurrentStep={handleChangeCurrentStep}
              previousStepIsLinkedinInvite={previousStepIsLinkedinInvite}
            />
          }
          <SequenceStepChoices
            handleChangeCurrentStep={handleChangeCurrentStep}
            handleSaveStep={handleSaveStep}
            currentStep={currentStep}
          />
        </>
      }
      {currentStep && currentStep.action_type === 'linkedin_message' &&
        <>
          {currentStep.order > 1 &&
            <StepDelayChoice
              currentStep={currentStep}
              handleChangeCurrentStep={handleChangeCurrentStep}
              previousStepIsLinkedinInvite={previousStepIsLinkedinInvite}
            />
          }
          <SequenceStepLinkedinMessage
            handleChangeCurrentStep={handleChangeCurrentStep}
            handleSaveStep={handleSaveStep}
            currentStep={currentStep}
          />
        </>
      }
      {currentStep && currentStep.action_type === 'linkedin_invite' &&
        <>
          {currentStep.order > 1 &&
            <StepDelayChoice
              currentStep={currentStep}
              handleChangeCurrentStep={handleChangeCurrentStep}
              previousStepIsLinkedinInvite={previousStepIsLinkedinInvite}
            />
          }
          <SequenceStepLinkedinInvite
            handleChangeCurrentStep={handleChangeCurrentStep}
            handleSaveStep={handleSaveStep}
            currentStep={currentStep}
          />
        </>
      }
      {currentStep && currentStep.action_type === 'email' &&
        <>
          {currentStep.order > 1 &&
            <StepDelayChoice
              currentStep={currentStep}
              handleChangeCurrentStep={handleChangeCurrentStep}
              handleSaveStep={handleSaveStep}
              previousStepIsLinkedinInvite={previousStepIsLinkedinInvite}
            />
          }
          <SequenceStepEmail
            handleChangeCurrentStep={handleChangeCurrentStep}
            handleSaveStep={handleSaveStep}
            currentStep={currentStep}
          />
        </>
      }
    </div>
  )
}

export default SequenceStepSetup
