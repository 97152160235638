import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Filters from 'features/Filters'
import SearchCompaniesResults from 'features/SearchCompaniesResults'
import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const SearchCompanies = () => {
  const { t } = useTranslation('global')
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState({})
  const [companiesResults, setCompaniesResults] = useState({companies: [], context: {}})

  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = useCallback(
    (page) => {
      setCurrentFilters(newFilters)
  
      apiClient
        .get(buildApiUrl(`/companies/search`, newFilters, page))
        .then((response) => {
          setCompaniesResults(response.data)
        })
        .catch((error) => {
          console.error('Error fetching companies:', error)
        });
    },
    [newFilters]
  );

  const handleAddCompany = (company) => {
    apiClient.post(buildApiUrl('/companies', {'company_linkedin_id': company.linkedin_id}))
      .then(() => {
        console.log("OK")
      })
      .catch((error) => {
        console.error('Error creating company:', error)
      })
  }

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])
  
  useEffect(() => {
    hasNewFilters && handleSubmitFilters()
  }, [])


  const companiesTotal = (companiesResults && companiesResults.context && companiesResults.context.total_count) || 0

  return (
    <Layout>
      <Topbar
        cta={t('save_search')}
        icon={<FontAwesomeIcon icon={faCity} />}
        title={t('search_new_companies')}
        number={companiesTotal}
      />
      <Tabs
        activeTab={{name: t('my_companies'), id: '45434'}}
        tabs={[{name: t('my_companies'), id: '45434'}]}
      />
      <div className='companies'>
        <Filters
          handleChangeFilters={handleChangeFilters}
          type='searchCompanies'
          newFilters={newFilters}
        />
        <SearchCompaniesResults
          companies={companiesResults.companies}
          searchContext={companiesResults.context}
          handleAddCompany={handleAddCompany}
          handleCancelFilters={handleCancelFilters}
          handleSubmitFilters={(page) => handleSubmitFilters(page)}
          hasNewFilters={hasNewFilters}
        />
      </div>
    </Layout>
  )
}

export default SearchCompanies
