import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faUserPlus, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons'

const SequenceStepChoices = ({ handleSaveStep, currentStep }) => {
  const { t } = useTranslation('global')
  const [actionType, setActionType] = useState(null)

  const handleSubmitAction = () => {
    const newStep = { ...currentStep, action_type: actionType }
    handleSaveStep(newStep)
  }

  const handleChangeActionType = (type) => {
    setActionType(type)
  }

  return (
    <div className='sequence_step_choices'>
      <p className='sequence_step_choices__title'>{t('choose_action')}</p>
      <p className='sequence_step_choices__desc'>{t('action_desc')}</p>
      <div className='sequence_step_choices__list'>
        <div
          className={`sequence_step_choice ${actionType === 'linkedin_invite' ? 'active' : ''}`}
          onClick={() => handleChangeActionType('linkedin_invite')}
        >
          <div className='sequence_step_choice__action'>
            <FontAwesomeIcon icon={faUserPlus} />
            <p className='sequence_step_choice__action__text'>{t('send_linkedin_invite')}</p>
          </div>
        </div>
        <div
          className={`sequence_step_choice ${actionType === 'linkedin_message' ? 'active' : ''}`}
          onClick={() => handleChangeActionType('linkedin_message')}
        >
          <div className='sequence_step_choice__action'>
            <FontAwesomeIcon icon={faComments} />
            <p className='sequence_step_choice__action__text'>{t('send_linkedin_message')}</p>
          </div>
        </div>
        <div
          className={`sequence_step_choice ${actionType === 'email' ? 'active' : ''}`}
          onClick={() => handleChangeActionType('email')}
        >
          <div className='sequence_step_choice__action'>
            <FontAwesomeIcon icon={faPaperPlane} />
            <p className='sequence_step_choice__action__text'>{t('send_email')}</p>
          </div>
        </div>
        <div
          className={`sequence_step_choice ${actionType === 'call' ? 'active' : ''}`}
          onClick={() => handleChangeActionType('call')}
        >
          <div className='sequence_step_choice__action'>
            <FontAwesomeIcon icon={faPhone} />
            <p className='sequence_step_choice__action__text'>{t('call')}</p>
          </div>
        </div>
      </div>
      <div className='main-btn' onClick={handleSubmitAction}>{t('next')}</div>
    </div>
  )
}

export default SequenceStepChoices
