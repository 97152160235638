import React from 'react'
import { useTranslation } from 'react-i18next'

const Text = ({ handleChangeValue, value }) => {
  const { t } = useTranslation('global')

  return (
    <div className='text'>
      <input
        className='text__input'
        onChange={(e) => handleChangeValue(e.target.value)}
        placeholder={t('type')}
        value={value || ''}
      />
    </div>
  )
}

export default Text
