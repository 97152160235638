import React, { useEffect, useState } from 'react'

const Toggle = ({handleChangeFilters, item, newValue }) => {
  const [value, setValue] = useState(newValue || false)

  const handleChangeValue = () => {
    handleChangeFilters(item, !value)
    setValue(!value)
  }

  useEffect(() => {
    setValue(newValue || false)
  }, [newValue])

  return (
    <div className={value ? 'toggle active' : 'toggle'} onClick={() => handleChangeValue()}>
      <div className='toggle__button'></div>
    </div>
  )
}

export default Toggle
