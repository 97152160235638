import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { AuthProvider } from "contexts/AuthProvider"
import ProtectedRoute from "routes/ProtectedRoute"

import Companies from 'pages/Companies'
import Conversations from 'pages/Conversations'
import Home from 'pages/Home'
import Lists from 'pages/Lists'
import Login from 'pages/Login'
import Integrations from 'pages/Integrations'
import People from 'pages/People'
import Profile from 'pages/Profile'
import SearchCompanies from 'pages/SearchCompanies'
import SearchPeople from 'pages/SearchPeople'
import Sequences from 'pages/Sequences'
import Settings from 'pages/Settings'
import Tasks from 'pages/Tasks'

import './styles/main.scss'

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/conversations" element={<Conversations />} />
            <Route path="/integrations" element={<Integrations />} />
            <Route path="/lists" element={<Lists />} />
            <Route path="/people" element={<People />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/search/companies" element={<SearchCompanies />} />
            <Route path="/search/people" element={<SearchPeople/>} />
            <Route path="/sequences" element={<Sequences />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/tasks" element={<Tasks />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App
