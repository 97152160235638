import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons'

const Select = ({choices, handleChangeFilters, item, isDisabled, newValue, placeholder}) => {
  const { t } = useTranslation('search')
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(newValue || '')
  const [search, setSearch] = useState('')
  const [filtredChoices, setFilteredChoices] = useState(choices)
  const selectRef = useRef(null)

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setValue(newValue || '')
  }, [newValue])

  useEffect(() => {
    const filtered = choices.filter((choice) =>
      (t(choice.id).toLowerCase().includes(search.toLowerCase()) || choice.id.toLowerCase().includes(search.toLowerCase()))
    )
    setFilteredChoices(filtered)
  }, [choices, search, t])

  const toggleList = () => {
    !isDisabled && setIsOpen(!isOpen)
  }

  const handleChangeValue = (choice) => {
    setValue(choice)
    setIsOpen(false)
    handleChangeFilters(item, choice)
  }

  const handleRemoveValue = () => {
    setValue('')
    handleChangeFilters(item, null)
  }

  const getChoiceIdFromValue = () => {
    const choice = choices.find(choice => choice.value == value)
    return choice ? choice.id : ''
  }

  const handleChangeSearch = (search) => {
    setSearch(search.toLowerCase())
  }

  return (
    <div className={isDisabled ? 'select disabled' : 'select'} ref={selectRef}>
      {value ?
        <div className='select__value'>
          <span>{t(getChoiceIdFromValue())}</span>
          {!isDisabled && <FontAwesomeIcon className='select__value__trash' icon={faTrash} onClick={handleRemoveValue} />}
        </div>
        :
        !isOpen &&
          <div className='select__button' onClick={toggleList}>
            <span>{placeholder ? t(placeholder) : t('select')}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
      }
      {isOpen &&
      <>
        <input
          className='select__button'
          value={search}
          onChange={(e) => handleChangeSearch(e.target.value)}
        />
        <ul className='select__list'>{filtredChoices.map(choice => <li key={choice.id} onClick={() => handleChangeValue(choice.value)}>{t(choice.id)}</li>)}</ul>
      </>
      } 
    </div>
  )
}

export default Select
