import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleExclamation, faEnvelope, faPhone, faEye, faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Select from './Select'

const ActionsCreator = ({ activeContact, handleChangeActiveContact, handleChangeSequence, handleSaveSequence, sequence }) => {
  const { t } = useTranslation('global')
  const [lists, setLists] = useState([])
  const [selectedListId, setSelectedListId] = useState(null)
  const [contacts, setContacts] = useState([])

  const fetchLists = () => {
    apiClient.get(buildApiUrl('/lists', {model: 'contact'}))
      .then((response) => {
        response.data && setLists(response.data)
      })
      .catch((error) => {
        console.error('Error creating list:', error)
      })
  }

  const fetchContactsLists = async (listsIds) => {
    if(listsIds && listsIds.length > 0) {
      try {
        const response = await apiClient.get(buildApiUrl(`/contacts`, { lists_ids: listsIds }))
        setContacts(response.data)
      } catch (error) {
        console.error('Error fetching contacts:', error)
      }
    }
    else {
      setContacts([])
    }
  }
  
  const handleChangeSelectedListId = (key, value) => {
    setSelectedListId(value)
  }

  const handleSelectList = async (listIdToAdd) => {
    const updatedListIds = [...sequence.list_ids, listIdToAdd]
  
    try {
      const updatedSequence = await handleChangeSequence('list_ids', updatedListIds)
      await handleSaveSequence(updatedSequence)
      await fetchContactsLists(updatedListIds)
    } catch (error) {
      console.error('Error in handleSelectList:', error)
    }
  }
  
  const handleRemoveList = async (listIdToRemove) => {
    const updatedListIds = sequence.list_ids.filter((id) => id != listIdToRemove)
  
    try {
      const updatedSequence = await handleChangeSequence('list_ids', updatedListIds)
      await handleSaveSequence(updatedSequence)
      await fetchContactsLists(updatedListIds)
    } catch (error) {
      console.error('Error in handleSelectList:', error)
    }
  }
  
  const handleValidateContact = (contactToValidateId) => {
    if(sequence) {
      apiClient.post(buildApiUrl(`/sequences/${sequence.id}/validate_contact`), {contact_id: contactToValidateId})
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error creating list:', error)
      })
    }
  }

  useEffect(() => {
    fetchLists()
    sequence && sequence.list_ids && sequence.list_ids.length && fetchContactsLists(sequence.list_ids)
  }, [])

  useEffect(() => {
    setSelectedListId(null)
  }, [sequence])

  const filteredList = lists?.filter(l => !sequence.list_ids.includes(l.id.toString())).map(l => ({ value: l.id, id: l.name }))
  const formattedTotalList = lists?.map(l => ({ value: l.id, id: l.name }))

  return (
    <div className='actions_creator'>
      {sequence.list_ids && sequence.list_ids.length > 0 && sequence.list_ids.map(listId => {
        return (
          <div key={listId} className='actions_creator__list already-added'>
            <Select
              choices={formattedTotalList}
              isDisabled={true}
              item={`list${listId}`}
              newValue={listId}
            />
            <div className='remove-btn' onClick={() => handleRemoveList(listId)}><FontAwesomeIcon icon={faTrash} /></div>
          </div>
        )
      })}
      <div className='actions_creator__list'>
        <Select
          choices={filteredList}
          handleChangeFilters={handleChangeSelectedListId}
          item='newList'
          newValue={selectedListId}
        />
        <div className='main-btn' onClick={() => handleSelectList(selectedListId)}><FontAwesomeIcon icon={faPlus} /></div>
      </div>
      <div className='actions_creator__contacts'>
      {contacts && contacts.length > 0 &&
        <div className='actions_creator__contacts__header'>
          <div>{t('name')}</div>
          <div></div>
          <div>{t('company')}</div>
          <div><FontAwesomeIcon icon={faLinkedin} /></div>
          <div><FontAwesomeIcon icon={faEnvelope} /></div>
          <div><FontAwesomeIcon icon={faPhone} /></div>
          <div>{t('validation')}</div>
        </div>
      }
        {contacts && contacts.length > 0 && contacts.map(contact => {
          return (
            <div key={contact.id} className={`actions_creator__contact ${activeContact?.id == contact.id ? 'active' : ''}`}>
              <img className='actions_creator__contact__img' src={contact.picture_url} alt={contact.first_name + ' ' + contact.last_name} />
              <div>{contact.first_name} {contact.last_name}</div>
              <div>{contact.company_name}</div>
              {contact.linkedin_id ?
                <FontAwesomeIcon className='actions_creator__contact__icon__green' icon={faCheckCircle} />
                :
                <FontAwesomeIcon icon={faCircleExclamation} />
              }
              {contact.is_validated_email ?
                <FontAwesomeIcon className='actions_creator__contact__icon__green' icon={faCheckCircle} />
                :
                <FontAwesomeIcon icon={faCircleExclamation} />
              }
              {contact.is_validated_phone ?
                <FontAwesomeIcon className='actions_creator__contact__icon__green' icon={faCheckCircle} />
                :
                <FontAwesomeIcon icon={faCircleExclamation} />
              }
              <div className='actions_creator__contact__ctas'>
                <div className='alt-btn' onClick={() => handleChangeActiveContact(contact)}><FontAwesomeIcon icon={faEye} /></div>
                <div className='main-btn' onClick={() => handleValidateContact(contact.id)}><FontAwesomeIcon icon={faCheck} /></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ActionsCreator
