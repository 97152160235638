import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ActionsCreator from 'components/ActionsCreator'
import Loader from 'components/Loader'
import SequenceStepCreator from '../components/SequenceStepCreator'
import Text from 'components/Text'
import TextArea from 'components/TextArea'

const SequenceBuilder = ({ activeContact, currentStep, handleChangeActiveContact, handleChangeSequence, handleChangeStage, handleChangeCurrentStep, handleSaveSequence, isLoading, sequence, stage}) => {
  const { t } = useTranslation('search')
  const [previousSequenceId, setPreviousSequenceId] = useState(sequence.id)
  
  const handleChangeStep = (newStage) => {
    if(stage === 1) {
      handleSaveSequence().then(() => {
        handleChangeStage(newStage)
      })
    } else {
      handleChangeStage(newStage)
    }
  }

  const getStepStatus = (testedStage) => {
    if (testedStage === 1) {
      if (!!sequence.name && stage === testedStage) return 'complete active'
      else if (!!sequence.name) return 'complete'
      else if(stage === testedStage) return 'active'
    } else if (testedStage === 2) {
      if (sequence.steps && sequence.steps.length > 0 && stage === testedStage) return 'complete active'
      else if (sequence.steps && sequence.steps.length > 0) return 'complete'
      else if(stage === testedStage) return 'active'
    } else if (testedStage === 3) {
      if (sequence.actions && sequence.actions.length > 0 && stage === testedStage) return 'complete active'
      else if (sequence.actions && sequence.actions.length > 0) return 'complete'
      else if(stage === testedStage) return 'active'
    } else {
      return 'incomplete'
    }
  }

  useEffect(() => {
    if (sequence && (sequence.id !== previousSequenceId)) {
      if(sequence.name && sequence.steps && sequence.steps.length > 0) {
        handleChangeStage(3)
      } else if(sequence.name && !sequence.steps) {
        handleChangeStage(2)
      } else {
        handleChangeStage(1)
      }
      setPreviousSequenceId(sequence.id)
    }
  }, [sequence.id, previousSequenceId])

  return (
    <div className='sequence-builder'>
      {isLoading ?
        <Loader />
        :
        <>
          <div className='sequence-builder__header'>
            <div className={`sequence-builder__header__item ${getStepStatus(1)}`} onClick={() => handleChangeStep(1)}>1</div>
            <div className='sequence-builder__header__separator'></div>
            <div className={`sequence-builder__header__item ${getStepStatus(2)}`} onClick={() => handleChangeStep(2)}>2</div>
            <div className='sequence-builder__header__separator'></div>
            <div className={`sequence-builder__header__item ${getStepStatus(3)}`} onClick={() => handleChangeStep(3)}>3</div>
          </div>
          {stage === 1 &&
            <div className='sequence-builder__step'>
              <p className='sequence-builder__step__intro'>Let's start by choosing your sequence's name and add a description.</p>
              <div className='sequence-builder__step__form-item'>
                <p className='sequence-builder__step__label'>Choose your sequence's name</p>
                <Text
                  handleChangeValue={(value) => handleChangeSequence('name', value)}
                  item='name'
                  value={sequence.name}
                />
              </div>
              <div className='sequence-builder__step__form-item'>
                <p className='sequence-builder__step__label'>Describe your sequence</p>
                <TextArea
                  handleChangeValue={(value) => handleChangeSequence('description', value)}
                  item='description'
                  value={sequence.description}
                />
              </div>
              <div className='main-btn' onClick={() => handleChangeStep(stage + 1)}>Next</div>
            </div>
          }
          {
            stage === 2 &&
            <div className='sequence-builder__step'>
              <p className='sequence-builder__step__intro'>It's time to add steps to your sequence!</p>
              <SequenceStepCreator
                currentStep={currentStep}
                handleChangeCurrentStep={handleChangeCurrentStep}
                handleChangeStage={handleChangeStep}
                stage={stage}
                steps={sequence.steps}
              />
            </div>
          }
          { stage === 3 &&
            <div className='sequence-builder__step'>
              <p className='sequence-builder__step__intro'>It's time to add people to your sequence!</p>
              <ActionsCreator
                activeContact={activeContact}
                handleChangeActiveContact={handleChangeActiveContact}
                handleChangeSequence={handleChangeSequence}
                handleSaveSequence={handleSaveSequence}
                sequence={sequence}
              />
            </div>
          }
        </>
      }
    </div>
  )
}

export default SequenceBuilder
