import React from 'react'

const Conversations = () => {

  return (
    <div>
      <h1>Conversations</h1>
    </div>
  )
}

export default Conversations
