import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const GlobalSearch = ({isSidebarOpen}) => {
  const { t } = useTranslation('global')

  return (
    <div className={isSidebarOpen ? 'global-search open' : 'global-search closed'}>
      <FontAwesomeIcon icon={faSearch} />
      {isSidebarOpen && <span className='global-search__text'>{t('quick_search')}</span>}
    </div>
  )
}

export default GlobalSearch
