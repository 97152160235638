import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity } from '@fortawesome/free-solid-svg-icons'

import apiClient from 'services/api'
import { buildApiUrl } from 'services/functions'

import Filters from 'features/Filters'
import SearchPeopleResults from 'features/SearchPeopleResults'
import Layout from 'layouts/Layout'
import Topbar from 'layouts/Topbar'
import Tabs from 'components/Tabs'

const SearchPeople = () => {
  const { t } = useTranslation('global')
  const [newFilters, setNewFilters] = useState({})
  const [currentFilters, setCurrentFilters] = useState({})
  const [contactsResults, setContactsResults] = useState({contacts: [], context: {}})


  const handleChangeFilters = (key, value) => {
    setNewFilters((prevNewFilters) => {
      const updatedFilters = { ...prevNewFilters }
  
      if (!value) {
        delete updatedFilters[key]
      } else {
        updatedFilters[key] = value
      }
  
      return updatedFilters
    })
  }
  
  const handleSubmitFilters = useCallback(
    (page) => {
      setCurrentFilters(newFilters)
  
      apiClient
        .get(buildApiUrl('/contacts/search', newFilters, page))
        .then((response) => {
          setContactsResults(response.data)
        })
        .catch((error) => {
          console.error('Error fetching contacts:', error)
        })
    },
    [newFilters]
  )

  const handleAddContact = (contact) => {
    apiClient.post(buildApiUrl('/contacts', {'linkedin_id': contact.linkedin_id}))
      .then((response) => {
        console.log("OK")
      })
      .catch((error) => {
        console.error('Error creating company:', error)
      })
  }

  const handleCancelFilters = () => {
    setNewFilters(currentFilters)
  }

  const hasNewFilters =
    Object.keys(newFilters).length !== Object.keys(currentFilters).length || 
    Object.keys(newFilters).some((key) => newFilters[key] !== currentFilters[key])

  useEffect(() => {
    hasNewFilters && handleSubmitFilters()
  }, [])


  const contactsTotal = (contactsResults && contactsResults.context && contactsResults.context.total_count) || 0

  return (
    <Layout>
      <Topbar
        cta={t('save_search')}
        icon={<FontAwesomeIcon icon={faCity} />}
        title={t('search_new_contacts')}
        number={contactsTotal}
      />
      <Tabs
        activeTab={{name: t('my_companies'), id: '45434'}}
        tabs={[{name: t('my_companies'), id: '45434'}]}
      />
      <div className='people'>
        <Filters
          handleChangeFilters={handleChangeFilters}
          type='searchPeople'
          newFilters={newFilters}
        />
        <SearchPeopleResults
          contacts={contactsResults.contacts}
          searchContext={contactsResults.context}
          handleAddContact={handleAddContact}
          handleCancelFilters={handleCancelFilters}
          handleSubmitFilters={handleSubmitFilters}
          hasNewFilters={hasNewFilters}
        />
      </div>
    </Layout>
  )
}

export default SearchPeople
