import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faUserPlus, faCircleCheck, faList, faBuilding } from '@fortawesome/free-solid-svg-icons'

import Pagination from 'components/Pagination'

const SearchPeopleResults = ({ contacts, handleAddContact, handleCancelFilters, handleSubmitFilters, hasNewFilters, searchContext }) => {
  const { t } = useTranslation('search')

  return (
    <div className={hasNewFilters ? 'results blurred' : 'results'}>
      {hasNewFilters && (
        <div className='results__filters-layer'>
          <p className='results__filters-layer__text'>{t('apply_filters_message')}</p>
          <div className='results__filters-layer__ctas'>
            <div className='results__filters-layer__ctas__cancel' onClick={handleCancelFilters}>{t('cancel')}</div>
            <div className='main-btn' onClick={() => handleSubmitFilters(null)}>{t('apply_filters')}</div>
          </div>
        </div>
      )}
      {!hasNewFilters && contacts.length > 0 &&
        <>
          <table className='results__table'>
            <thead>
              <tr>
                <th><input type='checkbox' /></th>
                <th>{t('full_name')}</th>
                <th>{t('job_title')}</th>
                <th>{t('location')}</th>
                <th>{t('industry')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => {
                return (
                  <tr key={contact.linkedin_id}>
                    <td><input type='checkbox' /></td>
                    <td className='results__table__with-picture'>
                      {contact.picture_url ?
                        <div className='results__table__with-picture__checked'>
                          <img src={contact.picture_url} alt={contact.name} className='results__table__picture' />
                          {contact.already_imported && <FontAwesomeIcon className='results__table__with-picture__checked_icon' icon={faCircleCheck} />}
                        </div>
                        :
                        <FontAwesomeIcon className='results__table__with-picture__placeholder' icon={faBuilding} />
                      }
                      <a className='results__table__link' href={'https://www.linkedin.com/in/' + contact.linkedin_id} target='_blank' rel="noreferrer">
                        {contact.full_name}
                        <FontAwesomeIcon className='results__table__link__icon' icon={faArrowUpRightFromSquare} />
                      </a>
                    </td>
                    <td>{contact.job_title}</td>
                    <td>{contact.location}</td>
                    <td>{contact.industry}</td>
                    <td className='results__actions'>
                      {!contact.already_imported && <FontAwesomeIcon className='results__actions__item' icon={faUserPlus} onClick={() => handleAddContact(contact)} />}
                      <FontAwesomeIcon className='results__actions__item' icon={faList} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Pagination paginationContext={searchContext} handleSubmitFilters={handleSubmitFilters} />
        </>
      }
    </div>
  )
}

export default SearchPeopleResults
